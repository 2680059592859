import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {CircularProgress,Paper,Box, Checkbox, ClickAwayListener, TextareaAutosize, Tooltip,TextField} from '@material-ui/core';
import {Lock, LockOpen, Search} from '@material-ui/icons';
import DraggableDialog from './modals/ref_sim_add'
import RefSimBdls from './modals/ref_sim_bdls'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import {AutocompleteS, InputNbrEditable, NoDataComponent,NotAllowedComponent, TelUpd} from "../../js/components"
import { AlertCompo } from "../../js/components";
import '../../css/referentiel.css'

export default function Ref_sim (props){
  const classes=useStyles();
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [codeL, setCodeL] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records,setRecords]=useState('');
  const [resetDP, setResetDP] = useState(false);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const [opr,setOpr]=useState([]);
  const [wilayas,setWilayas]=useState([]);
  const service='_sim.php'
  const srv='sim'
  const modes = Gfunc.getTypeCommande()

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }


 //fetch sim
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }   
    })  
  setLoading(false);  
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if (row[att] !== edt && !(!edt && !row[att])) {
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
         if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
        }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
  },[])
  
   //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setItem("")
    setItemN("")
    setSelecto('')
  },[librery,Gfunc]);

  //fetch Code
   const fetchCode= useCallback(async(serv="_sim.php", act = "getdist") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
            setCodeL(Object.entries(response.data.jData).map(([id, name]) => {
              name = (name !== "") ? name : "Aucun"
              return ({ id, name })
            }))
          }  
        })
    },[Gfunc,handleAlert])

    //fetch sim handler
  const handleOpenCode = useCallback(async () => {
    if (codeL.length === 0)
      fetchCode()
  }, [codeL, fetchCode])

  //fetch oprators
  const fetchOpr= useCallback(async() => {
    const response = await Gfunc.axiosPostAction("_bdl.php","getops")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else 
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
        setOpr(response.data.jData);   
    })
  },[Gfunc])

  //fetch operators handler
  const handleOpenOpr= useCallback(async () => {
    if(opr.length===0) fetchOpr()
  },[opr,fetchOpr])

  //fetch oprators
  const fetchWilayas= useCallback(async() => {
    const response = await Gfunc.axiosPostAction(service,"getwils")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else 
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
        setWilayas(response.data.jData);   
    })
  },[Gfunc])

  //fetch operators handler
  const handleOpenWilayas= useCallback(async () => {
    if(wilayas.length===0) fetchWilayas()
  },[wilayas,fetchWilayas])

  const flagMan =useCallback(async(row,att)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id:parseInt(row.DT_RowId.substr(1)),att:att, val: row[att] }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        var objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        const nouveauTableau = [...data]; 
        nouveauTableau[objIndex][att] = !row[att]; 
        setData(nouveauTableau);
      }
   })
},[Gfunc,data])

  const columns =useMemo(
    () => [ 
      {
        width: "30px !important",
        cell: (row) => { return (<div><RefSimBdls row={row} param={param} /></div>) }
      },
      {
          name: "Numéro",
          width:"110px !important",
          selector:row=>row.number,
          sortable: true,
          sortField:"number",
          cell: (row) => {
            return (
              <TelUpd datein={row.dateAdd} agentin={row.agentAdd} dateupd={row.dateUpd} agentupd={row.agentUpd}
                      service={service} action='upd' row={row} att="number" value={row.number} id='DT_RowId'handleAlert={handleAlert}/>
             );
           },
      },
      {
        name: "Code",
        minWidth:"100px !important",
        selector:row=>row.simCode,
        cell: (row) => {
          return (
        <div>
          <ContentEditable
            html={(row.simCode!==null)?row.simCode:''}
            name="simCode"
            data-column="item"
            className="content-editable"
            disabled={false} 
            onBlur={e => handleOnEdit(e,row,'simCode')}
          />
        </div >
           );
         },
    },
      {
        name: "Mode",
          minWidth:"80px !important",
        selector: row => row.mode,
          cell: (row) => {
            return (
          <div>
                <AutocompleteS service={service} action={'upd'} row={row} list={modes}
                  id={'mode'} name='modeName' attr='name' handleAlert={handleAlert} />
          </div >
             );
           },
      },
      {
        name: "auto-Charge",
        width: '120px !important',
        selector: row => row.autoCharge,
        cell: (row) => {
          return (
            <InputNbrEditable service={service} action='upd' min={1} row={row} att="autoCharge" value={row.autoCharge} id='DT_RowId'
              idi={row.DT_RowId} handleAlert={handleAlert} position="end" />
          );
        },
      },
    {
        name: "Bonus",
        width:'90px !important',
        sortable: true,
        sortField:"bonus",
        cell: (row) => {
          return (
            <div onClick={()=>flagMan(row,'bonus')} >
             <Checkbox checked={row.bonus} className={classes.checkGreen}/> 
            </div>
           );
         },
    },
    {
        name: "En Service",
        width:'110px !important',
        sortable: true,
        sortField:"onService",
        cell: (row) => {
          return (
            <div onClick={()=>flagMan(row,'onService')} >
             <Checkbox checked={row.onService} className={classes.checkGreen}/> 
            </div>
           );
         },
    },
    {
        name: "Wilaya",
        width:'120px !important',
        selector: row=>row.wilaya,
        sortable: true,
        sortField:"wilaya",
        cell: (row) => { 
          return (
               <AutocompleteS service={service} action={'upd'} defaultValue={row.wilaya} row={row} list={wilayas} 
                              id={'wilayaId'} name='wilaya' attr='wilaya' onOpen={handleOpenWilayas} handleAlert={handleAlert}/>
          );
       }, 
    },
    {
      name: "DATE",
      width:"100px !important",
      selector:row=>row.dateAdd,
      sortable: true,
      sortField:"dateAdd",
      cell: (row) => { 
        return (
             <div>
              <Tooltip title={row.agentAdd}>
                <span>
                 {row.dateAdd}
                </span>
              </Tooltip>
             </div>
        );
     }, 
    },
    {
      name: "Note",
      selector:row=>row.note,
      sortable: true,
      minWidth: '200px !important',
      sortField:'note',
      cell: (row) => {
        return (
          <div className="note-container">
          { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
              <ClickAwayListener onClickAway={() => { (note != null) ? handleOnEditNote(note, row, "note", 'upd') : setSelecto('') }}>
           <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
           /></ClickAwayListener>:
           ((row.note&&row.note.match(/\r/g) || []).length>=1)?
           <Tooltip title={row.note.replaceAll("\r", "/ ")}>
               <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
           </Tooltip>:
             <Tooltip title={row.note?row.note:''}>
           <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
           </div>
         );
       },
    },
    { 
      right: true, 
      cell: (row) => {
        return (
          <div className="rowGrid" id="oneIcons">
            <div onClick={() => flagMan(row, 'activ')} title="Activer/Désactiver">{(row.activ) ?
              <LockOpen className={classes.redIcon} fontSize="small" /> : <Lock className={classes.greenIcon} fontSize="small" />}
            </div>
          </div>

         );
       },
    },
    ], [handleOnEdit, handleAlert, handleOnEditNote, handleSelect, codeL, textAreaEdit, classes, selecto, note, Item, ItemN]);
 
  // sort function handler
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
          setLoading(true);
          const response=await librery.axiosSort(column,sortdirection,page,size,service)
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
          })     
          setLoading(false);
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
   }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }
  
  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }


  const ExpanableComponent = ({ data }) => {


    return (
      <Paper className='simDetPaper'>
        <div className=" grid-wrapper row1">
          <div className="flex-wrapper  ">
            <b>Distributeur: </b>
            <div className="editableDiv">
              <AutocompleteS service={service} action={'upd'} row={data} list={codeL}
                id={'distCode'} name='dist' attr='name' onOpen={handleOpenCode} handleAlert={handleAlert} />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>Opérateur: </b>
            <div className="editableDiv">
              <AutocompleteS service={service} action={'upd'} defaultValue={data.operator} row={data} list={opr}
                id={'operatorId'} name='operator' attr='name' onOpen={handleOpenOpr} handleAlert={handleAlert} />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>Monatnt max: </b>
            <div className="editableDiv">
              <InputNbrEditable service={service} action='upd' min={1} row={data} att="maxMount" value={data.maxMount} id='DT_RowId'
                idi={data.DT_RowId} handleAlert={handleAlert} position="end" />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>Opération max: </b>
            <div className="editableDiv">
              <InputNbrEditable service={service} action='upd' min={1} row={data} att="maxOps" value={data.maxOps} id='DT_RowId'
                idi={data.DT_RowId} handleAlert={handleAlert} position="center" />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>Seuil: </b>
            <div className="editableDiv">
              <InputNbrEditable service={service} action='upd' min={1} row={data} att="minimum" value={data.minimum} id='DT_RowId'
                idi={data.DT_RowId} handleAlert={handleAlert} position="center" />
            </div >
          </div>
        </div>

        <div className=" grid-wrapper row2">
          <div className="flex-wrapper  ">
            <b>PIN: </b>
            <div className="editableDiv">
              <ContentEditable
                html={(data.password !== null) ? data.password : ''}
                name="password"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e, data, 'password')}
              />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>MDP API: </b>
            <div className="editableDiv">
              <ContentEditable
                html={(data.passApi !== null) ? data.passApi : ''}
                name="mdpApi"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e, data, 'passApi')}
              />
            </div >
          </div>
          <div className="flex-wrapper  ">
            <b>Caractère ignoré du solde: </b>
            <div className="editableDiv">
              <ContentEditable
                html={(data.ignorSoldChar !== null) ? data.ignorSoldChar : ''}
                name="ignorSoldChar"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e, data, 'ignorSoldChar')}
              />
            </div >
          </div>

          <div className="flex-wrapper conLot">
            <b>Caractère ignoré du solde bonus: </b>
            <div className="editableDiv">
              <ContentEditable
                html={(data.ignorSoldCharBonus !== null) ? data.ignorSoldCharBonus : ''}
                name="ignorSoldCharBonus"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e, data, 'ignorSoldCharBonus')}
              />
            </div >
          </div>
        </div>
      </Paper>
    )
  }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
            <DraggableDialog handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} handleOpenWilayas={handleOpenWilayas} wilayas={wilayas}
                             handleOpenOpr={handleOpenOpr} opr={opr} handleOpenCode={handleOpenCode} codeL={codeL}/></div>
          {(doSearch)?
          <div className='search'>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
          <div>
            <DataTable 
              columns={columns}
              data={data}
              expandableRows={true}
              expandableRowsComponent={ExpanableComponent} 
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[20, 30, 50, 100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={1}
              paginationResetDefaultPage={resetDP}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}