import React,{useCallback, useState} from 'react';
import {TextField,Paper,Button,DialogActions,DialogContent,DialogTitle,Dialog,FormControlLabel,Checkbox} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import {axiosGetAction,axiosResponse,axiosPostAction,getDate} from'../../../Gfunc'
import downloadCsv from 'download-csv'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo, BackdropCompo}from '../../../js/components'
import Draggable from 'react-draggable';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';

//extraction client
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_con_extr(props) {
  
    const [open, setOpen] = React.useState(false);
    const labelText1="Client"
    const labelText2="Produit"
    const labelText3="Quantité"
    const addMessage="Extraction automatique"
    const service="_cli.php"
    const [client,setClient]=useState('')
    const [clientN,setClientN]=useState('')
    const [qte,setQte]=useState('')
    const [prod,setProd]=useState('')
    const [prodN,setProdN]=useState('')
    const [prodL,setProdL]=useState('')
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [action, setAction] =useState(false);
    const [checked, setChecked] =useState(true);

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  const handleChange=()=>{
    setChecked(!checked)
  }

    
    const handleClickOpen = () => { setOpen(true); fetchClients(); fetchProd(); setProd(''); setQte(''); setClient(''); setChecked(true)};

    const handleClose = () => { setOpen(false); };

     //fetch clients
  const fetchClients= async() => {
    const response=await axiosPostAction(service,"gets",{})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
     }else{
      axiosResponse(response,()=>{
        setData(response.data.jData);
      })} 
  }

    //fetch products
    const fetchProd= useCallback(async(serv="_prd.php", act = "getstockqte") => {
      const response = await axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
          setProdL(response.data.jData);    
      })
      }
    },[axiosResponse])
  
  
  //extarct digits
  const handleSubmit=async()=>{
    setAction(true)
    const response=await axiosPostAction("_dig.php","extractdigsauto",{clientId:client,prodDetId:prod,qte:parseInt(qte)})
    var result=[]
    if(client==='') handleAlert(true,'warning',"Veuillez sélectionner un client d'abord!")
    else {
      const headers = { serial: "SERIAL", digit: "DIGIT", nom: "NOM", date: "DATE" }; 
        if(response.data.err&&response.data.err!=='-1'){
             handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                  if(response.data.jData){
                    if(checked)
                      response.data.jData.map((ex) => {
                        result.push({
                          serial: ex.serial,
                          digit: `"${ex.digit}"`,
                          nom: `"${ex.nom}"`,
                          date: `"${ex.date}"`,
                        });
                        return true;
                      });
                    else
                        response.data.jData.map((ex)=>{
                          result.push( (({digit}) => ({digit}))(ex));
                          return true;
                        })
                    downloadCsv(result, headers, clientN + "_" + getDate() + "_" + prodN + "_" + result.length + ".txt");
                   // props.handleClearRows();
                    props.callback();
                    setOpen(false);
                    setClient('')
                    setChecked(true)
                }
        })
      }
    }
    setAction(false)  
  }
  
    
  return (
    <div>
      <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleClickOpen} startIcon={<GetApp />} >Extraction Auto</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
            <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
            <BackdropCompo className={classes.backdropDiag} open={action}/>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            <ValidatorForm  id="extrForm" onSubmit={handleSubmit} onError={errors => console.log(errors)}  > 
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iClient"
                                onChange={(e,newValue) =>{
                                    setClient(newValue.id)
                                    setClientN(newValue.name)
                                  }
                                  } 
                                options={data}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                onChange={(e,newValue) =>{
                                    setProd(newValue.prodId)
                                    setProdN(newValue.nom)}
                                  } 
                                options={prodL}
                                getOptionLabel={(option) => {return (option.nom +" ["+option.stock+"] ["+option.achat+" DA]")}}
                                getOptionSelected={(option, value) => option.prodId === value.prodId}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )}/>
                      <TextValidator className="smallText" label={labelText3}  size="small" type='number' id="iQte" name="iQte" InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} value={qte} variant="outlined"
                        onChange={e =>setQte(e.target.value)} required/>
                      
                      <FormControlLabel control={
                                                    <Checkbox
                                                    checked={checked}
                                                    onChange={e=>handleChange(e)}
                                                    name="checked"
                                                    color="primary"/> }
                                                    label="Inclure les numero de série"/>
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
                    <Button variant="contained" type='submit' form="extrForm" color="primary">TELECHARGER </Button>
                    <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        
      </Dialog>
      </div>
  );
}
