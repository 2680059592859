import React,{useState,useMemo,createRef,useRef,useCallback,useContext,createContext, useEffect} from 'react';
import {DialogContent,Paper,Dialog,IconButton,Button,MenuItem, Tooltip, Backdrop, CircularProgress} from '@material-ui/core';
import {Print, Today} from '@material-ui/icons';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { withStyles} from '@material-ui/core/styles';
import { useStyles } from '../css/muiStyle';
import ReactToPrint from 'react-to-print';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import {Collapse} from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { mdiDatabaseAlert, mdiDatabaseLock } from '@mdi/js';
import Iconn from "@mdi/react"
import NoAjust from '../img/noajust.png'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import '../css/global.css'
import '../css/component.css'
import Alert from '@material-ui/lab/Alert';
import ParamContext from '../Context';

//import QRCode from "react-qr-code";
//var zlib = require('zlib');
const Gfunc=require('../Gfunc')
const librery=require('./data_table_librery')


const paperProps={
    style:{
      width:"800px",
      maxWidth:"2000px",
      maxHeight:"95%",
     // height:"1000px",
      overflow:"auto"
    }
 }

/* const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    margin:"0px 1%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));*/

const useStyles2 = makeStyles((theme) => ({
  root: {
   /* width: '98%',
    margin:"0px 1%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },*/
    position:'fixed',
    bottom:"30px",
    right:'10px',
    zIndex: theme.zIndex.drawer + 1,
    width: '300px',
    opacity:1
  },
  root2: {
   /* width: '98%',
    margin:"0px 1%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },*/
    position:'fixed',
    top:"35px",
    zIndex: theme.zIndex.drawer + 1,
    width: '95%',
    marginLeft:'2.5%',
    marginRight:'2.5%',
    opacity:0.7
  },
   
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[className*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
 
export const NoDataComponent =((props) => {
    return (
    <div className='nodata'>
      {(props.ajust)?<img src={NoAjust} alt="NoData" className='notFound' />   
      :<Iconn path={mdiDatabaseAlert} size={'150px'} color={'gray'} />}
      <p className='message'>{props.message}</p>  
    </div>        
    );
  });

export const NoTableDataComponent =((props) => {
    return (
    <div className='nodata'>
      {(props.ajust)?<img src={NoAjust} alt="NoData" className='notFound' />   
      :<Iconn path={mdiDatabaseAlert} size={'150px'} color={'gray'} />}
      <p className='message'>{props.message}</p>  
    </div>        
    );
  });

/*
export function Detail_tiket(props) {
    const [detail,setDetail]=useState([]);
    const [open, setOpen] = useState(false);
    const componentRef = useRef();
  
    // fetch detail de tiket 
    const fetchDetail= async() => {  
      const response = await Gfunc.axiosPostAction("_vnt.php","gettiket",{idt:props.idt})
      Gfunc.axiosResponse(response,()=>{
         setDetail(response.data.jData);
         
         var deflated = zlib.deflateSync(Buffer.from(JSON.stringify(detail))).toString('base64');
         var inflated = zlib.inflateSync(Buffer.from(deflated, 'base64')).toString();
/*
         var compressed = LZString.compress(JSON.stringify(detail));
         console.log(compressed)
         var decompressed = LZString.decompress(compressed);
         console.log(decompressed)

      })
    };

    const handleClickOpen = () => {  
      fetchDetail();
      props.handleFetchMag();
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };


    const columns = useMemo(()=>[   
          {
              name: "Produit",
              selector: row=>row.produit,
              sortable: true,
          },
          {
              name: "QTE.",
              selector: row=>row.qte,
              sortable: true,
          },
          {
              name: "Prix",
              selector: row=>row.agent,
              sortable: true,
              cell:(row)=>{
                return (
                <div>
                  {new Intl.NumberFormat().format(row.mtn/row.qte)}
                </div>
                )
              }
          },
          {
              name: "Total",
              selector: row=>row.mtn,
              sortable: true,
              cell:(row)=>{
                return (
                <div>
                  {new Intl.NumberFormat().format(row.mtn)}
                </div>
                )
              }
          },
        ],[])

  return (
    <div>
      <div  className="clickable" onClick={handleClickOpen}>{props.num}</div> 
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
<DialogTitle id="simple-dialog-title" onClose={handleClose}>Dètail du ticket <ReactToPrint trigger={() => <Button color="primary" style={{width:"120px"}}startIcon={<Print/>}> Imprimer</Button>} content={() => componentRef.current} />
</DialogTitle>{  
}
<DialogContent ref={componentRef} >
    <div id="ticket">
            <div id='ticketHead'>
              <h3 style={{color:'grey'}}>{props.mag.entet_tiket_1}</h3>
              <br/>
              <p>{props.mag.adresse}</p>
              <p>{props.mag.tel}</p>
              <p>{props.mag.facebook}</p>
              <img src={param.logo+props.mag.logo} id='logo_ticket' alt='logo'/>
              </div>
            <hr />
              <div className="rowGrid" id="row-1">
                <p>Ticket n: <b>{detail.numero}</b></p>
                <p>Date: <b>{detail.date_tiket}</b></p>
                <p>Vendeur: <b>{detail.vendeur}</b></p>
              </div>
              <div className="rowGrid" id="row-2">
                <p>Client: <b>{detail.client}</b></p>
                <p>Ancien solde: <b>{new Intl.NumberFormat().format(detail.ex_solde)}</b></p>
              </div>
                <DataTable 
                  persistTableHead
                  columns={columns}
                  data={detail.ventes}
                  noHeader={true}
                  //customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
               <div className="rowGrid" id="row-3">
                <p>Nombre d'article: <b>{(detail.ventes)?detail.ventes.length:0}</b></p>
                <p >Total: <b>{new Intl.NumberFormat().format(detail.total)}</b></p>
              </div>
              <br/>
               <div className="rowGrid" id="row-4">
                <p>Versement: <b>{new Intl.NumberFormat().format(detail.versement)}</b></p>
                <p>Solde restant: <b>{new Intl.NumberFormat().format(detail.reste)}</b></p>
              </div>
              <hr />
              <p style={{textAlign:"center"}}>Merci pour votre fidélité - <b>www.irahaserv.com</b></p>
            </div>
            <hr className="dash"/>
             <div style={{width:'100%',textAlign:'center', paddingTop:'20px'}}>
              <QRCode level ="L" value={zlib.deflateSync(JSON.stringify(detail)).toString('base64')} />
            </div>
        </DialogContent>
      </Dialog>
      </div>
  );
}
*/
export class OutsideClickHandler extends React.Component {
  wrapperRef = createRef();

  static defaultProps = {
    onOutsideClick: () => {}
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onOutsideClick();
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

export const NotAllowedComponent =((props) => {
  return (
  <div className='nodata'>
    <div style={{width:"100%",padding:'0px auto'}}>
    <Iconn path={mdiDatabaseLock} size='150px' color="gray"/>
    </div>  
    <p className='message'>{props.message}</p>  
  </div>        
  );
});


export function Detail_Bon(props) {
  const param = useContext(ParamContext);
  const customStyles=require('../css/datatableStyle')
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [bon, setBon] = useState([]);
  const [boncat,setBoncat] = useState([]);
  const [choix,SetSelectCoix] = useState('');
  const [affich,SetAffich] = useState(false);
  const componentRef = useRef();
  const componentRef1 = useRef();

  const handleClickOpen = () => {  
      setOpen(true); 
      fetchBon();
      //fetchCat();
     // props.handleFetchMag()
      SetAffich(true)
      SetSelectCoix('')
  };
  const handleClose = () => { setOpen(false); };

  const fetchBon=async()=>{
    const response = await Gfunc.axiosPostAction("_ach.php","getbon",{bon:props.row.DT_RowId.substr(1)})
    Gfunc.axiosResponse(response,()=>{
        setBon(response.data.jData);
    })
  }

  const columns = useMemo(()=>[   
        {
            name: "Description",
            width:"350px",
            selector: row=>row.produit,
            sortable: true,
        },
        {
            name: "QTE.",
            selector: row=>row.qte,
            sortable: true,
        },
        {
            name: "Prix",
            sortable: true,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.prx)}
              </div>
              )
            }
        },
        {
            name: "Total",
            selector: row=>row.mtn,
            sortable: true,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.mtn)}
              </div>
              )
            }
        },
      ],[])
  const columnsc = useMemo(()=>[   
        {
            name: "Produit",
            selector: row=>row.nom_bq,
            sortable: true,
        },
        {
            name: "Categorie.",
            selector: row=>row.categorie,
            sortable: true,
        },
        {
            name: "Prix",
            sortable: true,
            cell:(row)=>{
              return (
              <div>
                {new Intl.NumberFormat().format(row.prix)}
              </div>
              )
            }
        },
        {
            name: "état",
            selector: row=>row.nom_ir,
            sortable: true,
        },
      ],[])
return (
  <div>
    {(props.icon)?<Print className={classes.blackIcon} fontSize="small" onClick={handleClickOpen}/>
    :<div  className="clickable" onClick={handleClickOpen}>{props.num}</div>}
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" PaperProps={paperProps}>
        <DialogTitle id="simple-dialog-title" onClose={handleClose}>
          <div className="rowGrid" id="bonBon">
          <div> Dètail du Bon</div>
          <TextField select  variant="outlined" onClick={(e) => e.stopPropagation()} className='smallText' label="Choisir Bon..." size="small" value={choix} onChange={e =>SetSelectCoix(e.target.value)}>
                              <MenuItem  value='bon' >Imprimer bon d'achat</MenuItem>
                              <MenuItem  value='cat'>Imprimer bon du catalogue</MenuItem>     
          </TextField>
          {(choix==="bon" || (affich===true && choix===''))?<ReactToPrint trigger={() => <Button color="primary" style={{width:"210px"}} startIcon={<Print/>}> Imprimer Bon d'achat</Button>} content={() => componentRef.current} />:null}
          {(choix==="cat")?<ReactToPrint trigger={() => <Button color="primary" style={{width:"fit-content"}} startIcon={<Print/>}> Imprimer Catalogue</Button>} content={() => componentRef1.current} />:null}
          </div>
        </DialogTitle>
        <DialogContent>
          {
            (choix==='bon' || (affich===true && choix===''))?
            <div className='bon-ach' ref={componentRef}>
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>iRaha services</b></p> 
                <p id='infos'>Magasin: <b>{localStorage.getItem('mag')}</b></p>
                <p id='infos'>Achteur: <b> {bon.achteur}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
              <div id='infoD'>
                <p id='infos'><b>{bon.frns}</b></p> 
                <p id='infos'><b>{bon.frns_adr}</b></p>
                <p id='infos'><b>{bon.frns_tel}</b></p>
                <p id='infos'>le <b>{bon.date}</b></p>
              </div>
            </div>
           <div>
           <DataTable 
                  persistTableHead
                  columns={columns}
                  data={bon.achats}
                  noHeader={false}
                  title={"Bon d'achat N°  "+bon.numero}
                  customStyles={customStyles.customStylesBon}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
            
            <div id='solde'>
                <div id='a'>
                <p id='mtn'>Total:<b> {new Intl.NumberFormat().format(bon.total)}</b></p>
                <p id='mtnM'>Ancien solde:<b> {new Intl.NumberFormat().format(bon.pre_sold)}</b></p>
                <p id='mtn'>Nouveau solde:<b> {new Intl.NumberFormat().format(bon.post_sold)}</b></p>
              </div> 
            </div> 
          </div> 
          </div>:null
        }
        {
            (choix==='cat')? 
            <div className='boncat' ref={componentRef1}>
            <div className='bon-head'> 
              <div id='infoG'>
                <p id='infos'><b>iRaha services</b></p> 
                <p id='infos'>Magasin: <b>{localStorage.getItem('mag')}</b></p>
                <p id='infos'>Achteur: <b>{bon.achteur}</b></p>
              </div>
              <img src={param.logo+localStorage.getItem('logo')} id='logo' alt='logo'/>
            </div>
            <div>
              <DataTable 
                  persistTableHead
                  columns={columnsc}
                  data={boncat}
                  noHeader={false}
                  title={"Bon de catalogue relatif au bon d'achat N°  "+bon.numero}
                  customStyles={customStyles.customStylesBon}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
               />
            </div>
            </div>:null
        }
        </DialogContent>
     </Dialog>
    </div>
);
}
/*
export function Detail_swap(props) {
  const [open, setOpen] = useState(false);
  const componentRef = useRef();
  const classes=useStyles();
  const [tick, settick] = useState([]);


const fetchtick=async()=>{
  const response = await Gfunc.axiosPostAction("_ret.php","gettik",{retour:props.row.DT_RowId.substr(1)})
  Gfunc.axiosResponse(response,()=>{
      settick(response.data.jData);
  })
}
  const handleClickOpen = () => {  
    setOpen(true); 
    props.handleFetchMag();
    fetchtick();
  };
  const handleClose = () => { setOpen(false); };

return (
  <div>
     <Receipt className={classes.blueIcon} onClick={handleClickOpen} fontSize="small"/>
    <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={paperProps}
    >
<DialogTitle id="simple-dialog-title" onClose={handleClose}>Dètail du ticket <ReactToPrint trigger={() => <Button color="primary" style={{width:"120px"}} startIcon={<Print/>}> Imprimer</Button>} content={() => componentRef.current} />
</DialogTitle>{   //     <Button variant="contained" color="primary" style={{width:"120px"}}> Imprimer</Button>
}
<DialogContent ref={componentRef} >
  <div id="ticket" >
      <div id='ticketHead'>
                  <h3  id='titre'>{props.mag.entet_tiket_1}</h3>
                  <br/>
                  <p>{props.mag.adresse}</p>
                  <p>{props.mag.tel}</p>
                  <p>{props.mag.facebook}</p>
                  <img src={param.logo+props.mag.logo} id='logo_ticket' alt='logo'/>
                  </div>
                <hr />
            <div id='divinfo'>
            <div  id="row1"> 
                <div  style={{textAlign:"start"}}> 
                  <p><b>Client:</b> {props.row.client}</p> 
                </div>
                <div  style={{textAlign:"start"}}> 
                  <p><b>Agent:</b> {localStorage.getItem('name')}</p> 
                </div>
                {
                (props.row.reponseId===1)? 
                  <div style={{textAlign:"end"}}>
                  <p><b>Date de swap:</b> {tick.date_swap}</p>
                </div>:null
                }
                {
                (props.row.reponseId===2)? 
                  <div style={{textAlign:"end"}}>
                  <p><b>Date:</b> {tick.date_remboursement}</p>
                </div>:null
                }
            </div>
          {
           (props.row.reponseId===1)? 
           <div id="prod">
              <div >
                  <h3>{props.row.produit}</h3>
              </div>
              <div >
                  <h3>{props.row.serie}</h3>
              </div>
           </div>:null
          }

         {
           (props.row.reponseId===2)? 
           <div id='row2'>
              <div >
                  <h3>{props.row.produit}</h3>
              </div>
              <div style={{textAlign:"right"}}>
                <h3>{new Intl.NumberFormat().format(tick.mtn)} </h3>
              </div>
           </div>:null
        }
        </div>
   </div>
  
      </DialogContent>
    </Dialog>
    </div>
);
}*/
//Update in select item modified than others 

export const AutocompleteS= React.memo(({service,action,row,list,defaultValue,id,name,onOpen,attr,attr2,handleAlert,from,rowId,dateupd,agentupd,datein,
                               agentin,handleResponse,men,title})=> {
  const classes = useStyles();
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [value,setValue]=useState('')
  
const handleSelect=(row,att)=>{
  setSelecto(row[att])
  setItem(row[rowId])
  setItemN(att)
}
  const handleOnEditSelect=async(val,attList,f=()=>{})=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(row[id]!==val){
      const edt=val
      const response=await librery.axiosEdit2(service,action,row,edt,id,rowid) 
    if(response.data.err&&response.data.err!=='1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        const objIndex =list.findIndex((obj => obj.id === edt));
        //handleRowChange(row,name,list[objIndex][attList])
        row[name]=list[objIndex][attList]
        setValue(row[name])
        row[id]=edt
        if(handleResponse){
          handleResponse(response.data)
        }
        setSelecto('')
    })
  }
  }
  const handleOnEditSelect1=async(val,attList)=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(row[id]!==val){
      const edt=val
      const response=await librery.axiosEditCombine(service,action,row,edt,id) 
    if(response.data.err&&response.data.err!=='1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        const objIndex =list.findIndex((obj => obj.id === edt));
        //handleRowChange(row,name,list[objIndex][attList])
        row[name]=list[objIndex][attList]
        setValue(row[name])
        row[id]=edt
        if(handleResponse){
          handleResponse(response.data)
        }
        setSelecto('')
    })
  }
  }
  return(
    <Tooltip title={(title!=undefined)?title:(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className={(from==='inf')?"smallSelectDT2":"smallSelectDT"}>
  {
    ((row[rowId]===Item || from==='inf') && row[name]===selecto && ItemN===name)?
    <ClickAwayListener onClickAway={()=>{setSelecto('');setItemN('')}}>
    <div>
      <Autocomplete 
        disableClearable
        id={id}
        onBlur={()=>setSelecto('')}
        loading={list.length===0}
        loadingText="Chargement..."
        defaultValue={(typeof row[name] === 'number')?row[name].toString():row[name]}
        //getOptionSelected={(option, value) => option[attr] === value}
                  onOpen={(e) => { if (onOpen) { e.preventDefault(); onOpen() } }}
        onChange={(e,newValue)=>{e.stopPropagation();
          (men!=='prix')?(attr2!=undefined)?
          handleOnEditSelect(list[Gfunc.getIndex(list,newValue.split(" ")[0],attr)].id,attr):handleOnEditSelect(list[Gfunc.getIndex(list,newValue,attr)].id,attr)
                                                                   :handleOnEditSelect1(list[Gfunc.getIndex(list,newValue,attr)].id,attr)}}
        options={list.map((option) => {
          return( option[attr] + ((attr2!=undefined&&option[attr2]!=null)?` [ ${option[attr2]} ]`:""))
        })}
        inputprops={{className:classes.diagFormInput}}
        renderInput={(params) => (
        <TextField {...params} color="primary" variant="outlined" size="small"/>)} /></div></ClickAwayListener>
      :(row[name]!==null)? <div className={(from==='detail')?'bordersEditable':"content-editable"} id={id} onClick={()=>{handleSelect(row,name)}}>{(value==='')?row[name]:value}</div>
      :<div className="div-vide" id={id} onClick={()=>{handleSelect(row,name)}}></div>
  }
  </div></Tooltip>
  )
})


export function DatePickerUpd({service,action,name,row,selecto,ItemN,Item,setItem,setSelecto,handleSelect,minDate,maxDate,extra,handleAlert,rowId,dateupd,agentupd,datein,agentin}) {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleOnEditDate=useCallback(async(date,row,att)=>{
    const rowid=(rowId!==undefined)?rowId:"DT_RowId"
    if(Object.prototype.toString.call(date) === "[object Date]"&&!isNaN(date)){
      const edt=Gfunc.formatDate(date);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,name,rowid) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        setSelecto('')
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setSelectedDate(new Date())
   }
  },[action,handleAlert,name,service,setSelecto,rowId]);


  return(
    <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
    <div className="smallSelectDT">
  { (row[name]===selecto && row.DT_RowId===Item && ItemN===name)?
             <ClickAwayListener onClickAway={()=>{setItem('');setSelecto('')}}>
               <div>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  size="small"
                  TextFieldComponent={(params) => (
                      <TextField {...params} color="primary" variant="outlined" size="small"/>)}
                        className={classes.selectEdit2}
                        inputProps={{className:classes.inputEdit2}}
                        KeyboardButtonProps={{style:{padding:"0px"}}}
                        maxDate={(maxDate===undefined)?new Date():maxDate}
                        minDate={minDate}
                        invalidDateMessage="Le format de la date est incorrect"   
                        maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                        inputVariant="outlined"
                        variant="inline"
                        format="dd-MM-yyyy"
                        name="iDate"
                        value={selectedDate}
                        keyboardIcon={<Today className={classes.whiteSmallIcon}/>}
                        onChange={(date,e)=>{
                            setSelectedDate(date);
                            handleSelect(row,name);
                            handleOnEditDate(date,row,name)
                        }}
                        required
                />
              </MuiPickersUtilsProvider></div></ClickAwayListener>:
            <div className='content-editable' onClick={()=>{setSelectedDate(new Date(row[name]));
                  var dateParts = row[name].split("-");
                  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                  setSelectedDate(new Date(dateObject));
                  handleSelect(row,name);
            }}>{(extra!=='')?(row[name].substring(0, 5)+extra):(row[name])} </div>}
  </div></Tooltip>
  )
}

export const AlertCompo=(props)=>{
  const classes=useStyles2();
  return (
    <div className={(props.dialg)?classes.root2:classes.root}>
      <Collapse in={props.open}>
        <Alert
        variant="filled"
        severity={props.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.setOpen();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.message}
        </Alert>
      </Collapse>
    </div>
  )
}
// editable input of type number 
export function InputNbrEditable({service,handleAlert,row,att,action,id,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,
                                  positif,handleResponse,men,position}) {
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
 //update field for formatted numbers
  const handleOnEdit2 = useCallback(async (val) => {
  var edt=null
    if (val !== '' || men === 'bundle') {
      const edt1 = val.toString()
  if(edt1!==''){
     edt=JSON.parse(edt1.replace(/\s/g, ''));
  }
      if(row[att]!==edt&&!isNaN(edt)){
        const attrib=row[att]; 
        if(positif && edt<0) edt=-edt
        const response=await librery.axiosEdit2(service,action,row,edt,att,id) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=(edt!=='')?edt:''
          val = (edt !== '') ? new Intl.NumberFormat().format(edt) : ''
          if(traetResp!==undefined){traetResp(response.data)}
          if(handleResponse){handleResponse(row)}
         })
        if(response.data.err) {setVal(attrib)}
      }}
  }, [librery, Gfunc, row, att, action, id, traetResp, handleAlert, positif, service, val]);

  const handleOnEdit = useCallback(async (val) => {
    if (val !== '') {
      const edt1 = val.toString()
  var edt=JSON.parse(edt1.replace(/\s/g, ''));
      if(row[att]!==edt&&!isNaN(edt)){
        const attrib=row[att]; 
        if(positif && edt<0) edt=-edt
        const response=await librery.axiosEditCombine(service,action,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
          val = new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined){traetResp(response.data)}
          if(handleResponse){handleResponse(row)}
         })
        if(response.data.err) {setVal(attrib)}
      }}
},[librery,Gfunc,row,att,action,id,traetResp,handleAlert,positif,service]);
 
  
return (
  <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className={(position==='center')?'InputNbrEditable2':'InputNbrEditable'}>
  {(open!==row[id])?<div id={idi} className="content-editable" style={{textAlign:(position==='center')?'center':'end'}} 
        onClick={()=>setOpen(row[id])}>{(val!==null)?Gfunc.formatNumber(val):''}</div>
        : <ClickAwayListener onClickAway={() => { (men === 'prix') ? handleOnEdit(val) : handleOnEdit2(val); setOpen(''); }}>
  <input
      id={idi}
      type='number'
      value={(val===null)?'':val}
      data-column="item"
      className="content-editable"
      onChange={(e)=>{
        if(min!==undefined&&min!=null&&min!==''){
         if(min>=0&&e.target.value.includes('-'))
          {handleAlert(true,'error',"Veuillez saisir un montant positif!");setVal(row[att]);setOpen('')}
          else
          setVal(e.target.value)
        }else setVal(e.target.value)
      }}
      autoFocus
      min={min}
      max={max}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                { (men === 'prix') ? handleOnEdit(val) : handleOnEdit2(val) }; setOpen('');
              }
            }}
  />
  </ClickAwayListener>
  }
  </div>
  </Tooltip>

);
}

//backdrop for loading actions
export function BackdropCompo(props) {
  const classes=useStyles();

return (
  <Backdrop
        className={props.className}
        open={props.open}
      >
        <CircularProgress color="inherit" />
  </Backdrop>
);
}

//loading circle
export function LoadingCircle(props) {
  const classes=useStyles();

return (
        <div>
           {(props.loading)?<CircularProgress color="inherit" size={(props.size)?props.size:"20px"} style={{color:(props.color)?props.color:"red"}}/>:null}
        </div>
);
}

// editable input of type number 
export function TelUpd({service,handleAlert,row,att,action,id,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,positif,loading,setLoading,charge,supp,suppAtt,title}) {
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
  const [load, setLoad] =useState(false);
  
 //update field for formatted numbers
 //update field for formatted numbers
 const handleOnEdit2=useCallback(async(val)=>{
  if(val!==''){
    const check=Gfunc.phonenumber(val)
  if(check.response &&val!==null){
  setLoad(true)
  const edt1=val
  var edt=edt1.replace(/\s/g, '');
      if(row[att]!==edt&&edt!==""){
        const attrib=row[att]; 
        const response=await librery.axiosEdit2(service,action,row,edt,att,id,"number",supp,suppAtt) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
         // e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
        })
        if(response.data.err) {setVal(attrib)}
      }
      setLoad(false)
    }
    else {
        handleAlert(true,'error',check.message);setVal(value)}
    setOpen('')
    }
},[librery,Gfunc,row,att,action,id,traetResp]);

useEffect(async() => {
  if(open===row[id]){
    const elem=document.getElementById(idi)
    if (elem) {elem.focus(); elem.select()}
  }
}, [open]);
  
return (
  <Tooltip title={(title!=undefined)?title:(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className='InputNbrEditable'>
  {(open!==row[id])?<div id={idi} className="content-editable" style={{textAlign:'center',justifyContent:"center"}} onClick={()=>setOpen(row[id])}>{(val!==null)?val:''}</div>
  :<ClickAwayListener onClickAway={e => {if(changed){handleOnEdit2(val);}else setOpen('')}}>
    {(!load)?
    <input 
    id={idi}
    key={row[id]}
    //onBlur={()=>{setSelecto('');setValue('')}}
    defaultValue={value}
    value={(val===null)?'':val}
    type="number"  
    style={{color:"black !important"}}
    name="iTel" className='content-editable removeArrow' margin='dense' variant='outlined'
    autoFocus
    onChange={(e)=>{
      if(value!==e.target.value) setChanged(true); else setChanged(false)
      if(e.target.value && e.target.value.length >=10){setVal(e.target.value.substr(0,10))}else{setVal(e.target.value)}
    }}
    onKeyPress={(e)=>{
      if(e.key==="Enter"){
      handleOnEdit2(val);}
      }}
    required
    /> 
:<CircularProgress style={{width:"12px",height:"12px"}} color="inherit" />}
  </ClickAwayListener>
  }
  </div>
  </Tooltip>

);
}